!function () {
  if ("undefined" != typeof Prism && "undefined" != typeof document) {
    var e = {
      javascript: "clike",
      actionscript: "javascript",
      apex: ["clike", "sql"],
      arduino: "cpp",
      aspnet: ["markup", "csharp"],
      birb: "clike",
      bison: "c",
      c: "clike",
      csharp: "clike",
      cpp: "c",
      cfscript: "clike",
      chaiscript: ["clike", "cpp"],
      cilkc: "c",
      cilkcpp: "cpp",
      coffeescript: "javascript",
      crystal: "ruby",
      "css-extras": "css",
      d: "clike",
      dart: "clike",
      django: "markup-templating",
      ejs: ["javascript", "markup-templating"],
      etlua: ["lua", "markup-templating"],
      erb: ["ruby", "markup-templating"],
      fsharp: "clike",
      "firestore-security-rules": "clike",
      flow: "javascript",
      ftl: "markup-templating",
      gml: "clike",
      glsl: "c",
      go: "clike",
      gradle: "clike",
      groovy: "clike",
      haml: "ruby",
      handlebars: "markup-templating",
      haxe: "clike",
      hlsl: "c",
      idris: "haskell",
      java: "clike",
      javadoc: ["markup", "java", "javadoclike"],
      jolie: "clike",
      jsdoc: ["javascript", "javadoclike", "typescript"],
      "js-extras": "javascript",
      json5: "json",
      jsonp: "json",
      "js-templates": "javascript",
      kotlin: "clike",
      latte: ["clike", "markup-templating", "php"],
      less: "css",
      lilypond: "scheme",
      liquid: "markup-templating",
      markdown: "markup",
      "markup-templating": "markup",
      mongodb: "javascript",
      n4js: "javascript",
      objectivec: "c",
      opencl: "c",
      parser: "markup",
      php: "markup-templating",
      phpdoc: ["php", "javadoclike"],
      "php-extras": "php",
      plsql: "sql",
      processing: "clike",
      protobuf: "clike",
      pug: ["markup", "javascript"],
      purebasic: "clike",
      purescript: "haskell",
      qsharp: "clike",
      qml: "javascript",
      qore: "clike",
      racket: "scheme",
      cshtml: ["markup", "csharp"],
      jsx: ["markup", "javascript"],
      tsx: ["jsx", "typescript"],
      reason: "clike",
      ruby: "clike",
      sass: "css",
      scss: "css",
      scala: "java",
      "shell-session": "bash",
      smarty: "markup-templating",
      solidity: "clike",
      soy: "markup-templating",
      sparql: "turtle",
      sqf: "clike",
      squirrel: "clike",
      stata: ["mata", "java", "python"],
      "t4-cs": ["t4-templating", "csharp"],
      "t4-vb": ["t4-templating", "vbnet"],
      tap: "yaml",
      tt2: ["clike", "markup-templating"],
      textile: "markup",
      twig: "markup-templating",
      typescript: "javascript",
      v: "clike",
      vala: "clike",
      vbnet: "basic",
      velocity: "markup",
      wiki: "markup",
      xeora: "markup",
      "xml-doc": "markup",
      xquery: "markup"
    },
        a = {
      html: "markup",
      xml: "markup",
      svg: "markup",
      mathml: "markup",
      ssml: "markup",
      atom: "markup",
      rss: "markup",
      js: "javascript",
      g4: "antlr4",
      ino: "arduino",
      "arm-asm": "armasm",
      art: "arturo",
      adoc: "asciidoc",
      avs: "avisynth",
      avdl: "avro-idl",
      gawk: "awk",
      sh: "bash",
      shell: "bash",
      shortcode: "bbcode",
      rbnf: "bnf",
      oscript: "bsl",
      cs: "csharp",
      dotnet: "csharp",
      cfc: "cfscript",
      "cilk-c": "cilkc",
      "cilk-cpp": "cilkcpp",
      cilk: "cilkcpp",
      coffee: "coffeescript",
      conc: "concurnas",
      jinja2: "django",
      "dns-zone": "dns-zone-file",
      dockerfile: "docker",
      gv: "dot",
      eta: "ejs",
      xlsx: "excel-formula",
      xls: "excel-formula",
      gamemakerlanguage: "gml",
      po: "gettext",
      gni: "gn",
      ld: "linker-script",
      "go-mod": "go-module",
      hbs: "handlebars",
      mustache: "handlebars",
      hs: "haskell",
      idr: "idris",
      gitignore: "ignore",
      hgignore: "ignore",
      npmignore: "ignore",
      webmanifest: "json",
      kt: "kotlin",
      kts: "kotlin",
      kum: "kumir",
      tex: "latex",
      context: "latex",
      ly: "lilypond",
      emacs: "lisp",
      elisp: "lisp",
      "emacs-lisp": "lisp",
      md: "markdown",
      moon: "moonscript",
      n4jsd: "n4js",
      nani: "naniscript",
      objc: "objectivec",
      qasm: "openqasm",
      objectpascal: "pascal",
      px: "pcaxis",
      pcode: "peoplecode",
      plantuml: "plant-uml",
      pq: "powerquery",
      mscript: "powerquery",
      pbfasm: "purebasic",
      purs: "purescript",
      py: "python",
      qs: "qsharp",
      rkt: "racket",
      razor: "cshtml",
      rpy: "renpy",
      res: "rescript",
      robot: "robotframework",
      rb: "ruby",
      "sh-session": "shell-session",
      shellsession: "shell-session",
      smlnj: "sml",
      sol: "solidity",
      sln: "solution-file",
      rq: "sparql",
      sclang: "supercollider",
      t4: "t4-cs",
      trickle: "tremor",
      troy: "tremor",
      trig: "turtle",
      ts: "typescript",
      tsconfig: "typoscript",
      uscript: "unrealscript",
      uc: "unrealscript",
      url: "uri",
      vb: "visual-basic",
      vba: "visual-basic",
      webidl: "web-idl",
      mathematica: "wolfram",
      nb: "wolfram",
      wl: "wolfram",
      xeoracube: "xeora",
      yml: "yaml"
    },
        r = {},
        s = "components/",
        i = Prism.util.currentScript();

    if (i) {
      var t = /\bplugins\/autoloader\/prism-autoloader\.(?:min\.)?js(?:\?[^\r\n/]*)?$/i,
          c = /(^|\/)[\w-]+\.(?:min\.)?js(?:\?[^\r\n/]*)?$/i,
          l = i.getAttribute("data-autoloader-path");
      if (null != l) s = l.trim().replace(/\/?$/, "/");else {
        var p = i.src;
        t.test(p) ? s = p.replace(t, "components/") : c.test(p) && (s = p.replace(c, "$1components/"));
      }
    }

    var n = Prism.plugins.autoloader = {
      languages_path: s,
      use_minified: !0,
      loadLanguages: m
    };
    Prism.hooks.add("complete", function (e) {
      var a = e.element,
          r = e.language;

      if (a && r && "none" !== r) {
        var s = function (e) {
          var a = (e.getAttribute("data-dependencies") || "").trim();

          if (!a) {
            var r = e.parentElement;
            r && "pre" === r.tagName.toLowerCase() && (a = (r.getAttribute("data-dependencies") || "").trim());
          }

          return a ? a.split(/\s*,\s*/g) : [];
        }(a);

        /^diff-./i.test(r) ? (s.push("diff"), s.push(r.substr("diff-".length))) : s.push(r), s.every(o) || m(s, function () {
          Prism.highlightElement(a);
        });
      }
    });
  }

  function o(e) {
    if (e.indexOf("!") >= 0) return !1;
    if ((e = a[e] || e) in Prism.languages) return !0;
    var s = r[e];
    return s && !s.error && !1 === s.loading;
  }

  function m(s, i, t) {
    "string" == typeof s && (s = [s]);
    var c = s.length,
        l = 0,
        p = !1;

    function k() {
      p || ++l === c && i && i(s);
    }

    0 !== c ? s.forEach(function (s) {
      !function (s, i, t) {
        var c = s.indexOf("!") >= 0;

        function l() {
          var e = r[s];
          e || (e = r[s] = {
            callbacks: []
          }), e.callbacks.push({
            success: i,
            error: t
          }), !c && o(s) ? u(s, "success") : !c && e.error ? u(s, "error") : !c && e.loading || (e.loading = !0, e.error = !1, function (e, a, r) {
            var s = document.createElement("script");
            s.src = e, s.async = !0, s.onload = function () {
              document.body.removeChild(s), a && a();
            }, s.onerror = function () {
              document.body.removeChild(s), r && r();
            }, document.body.appendChild(s);
          }(function (e) {
            return n.languages_path + "prism-" + e + (n.use_minified ? ".min" : "") + ".js";
          }(s), function () {
            e.loading = !1, u(s, "success");
          }, function () {
            e.loading = !1, e.error = !0, u(s, "error");
          }));
        }

        s = s.replace("!", "");
        var p = e[s = a[s] || s];
        p && p.length ? m(p, l, t) : l();
      }(s, k, function () {
        p || (p = !0, t && t(s));
      });
    }) : i && setTimeout(i, 0);
  }

  function u(e, a) {
    if (r[e]) {
      for (var s = r[e].callbacks, i = 0, t = s.length; i < t; i++) {
        var c = s[i][a];
        c && setTimeout(c, 0);
      }

      s.length = 0;
    }
  }
}();
export default {};